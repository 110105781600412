import React from 'react';

const Portfolio = () => {
    return (
        <section id="portfolio">
            <div className="container">
                <h2>Our Portfolio</h2>
                <p>We work with a diverse range of companies to help them achieve their growth potential.</p>
            </div>
        </section>
    );
}

export default Portfolio;
