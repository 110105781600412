import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Team from './pages/Team';
import Contact from './pages/Contact';
import SuccessPage from './pages/SuccessPage';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App"> {/* Make sure this uses flex column layout */}
                <Navbar />
                <div className="content"> {/* New wrapper for content */}
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/portfolio" element={<Portfolio />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/success" element={<SuccessPage />} />
                    </Routes>
                </div>
                <Footer /> {/* Ensure the footer comes after the content */}
            </div>
        </Router>
    );
}

export default App;