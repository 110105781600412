import React from 'react';
import './AlternatingSections.css';

export function AlternatingSections({ sections }) {
  return (
    <div className="alternating-section">
      {sections.map((section, index) => (
        <div
          key={index}
          className={`section ${index % 2 === 0 ? 'text-left' : 'text-right'}`}
        >
          <div className="text">
            <h2>{section.title}</h2>
            <p>{section.text}</p>
          </div>
          <div className="image">
            <img src={section.image} alt={section.title} />
          </div>
        </div>
      ))}
    </div>
  );
}
