import React from 'react';
import styled from 'styled-components';
import DetailsBar from '../components/DetailsBar';
import InputSide from '../components/InputSide';
import image from '../assets/images/bg_patterns2.jpeg'


const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  background-color: whitesmoke;
  padding-bottom: 50px;
  background-image: url(${image});
  background-size: fill;
  

`;

const PageHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
  margin-top: 50px;
`;

const FormContainer = styled.div`
  width: 65%;
  min-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  height: auto;
  grid-gap: 10px;
  margin-top: 50px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 90%;
    max-width: 500px;
    min-width: 0px;
    grid-gap: 0px;
  }
`;

const TextOne = styled.b`
  font-size: 40px;
  font-family: 'Playfair Display';
  color: rgb(4, 4, 59);
  text-align: center;
`;

const TextTwo = styled.p`
  color: rgb(4, 4, 34);
  font-size: 22px;
  font-family: 'Lato';
  text-align: center;
`;

const Contact = () => {
  return (
  
         <PageWrapper>
      <PageHeadingWrapper>
        <TextOne>Contact US</TextOne>
        <TextTwo>Get in touch with us to learn more about our investment approach and how we can work together.</TextTwo>
      </PageHeadingWrapper>
      <FormContainer>
        <DetailsBar/>
        <InputSide/>
      </FormContainer>
      
    </PageWrapper>

 

    
    
  );
};

export default Contact;


