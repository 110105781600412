import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import Headshot from '../assets/images/headshot-placeholder.png'
import Farhan from '../assets/images/Farhan.jpg'
import Taqi from '../assets/images/Taqi.jpg'


function Cards() {
  return (
    <div className='cards'>
     
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src= {Taqi}
              text='Operations and Development'
              label='Taqi Anwar'
              //path='/services'
            />
            <CardItem
              src={Farhan}
              text='Investment and Growth'
              label='Masud Farhan'
              //path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;