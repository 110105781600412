import React from 'react';
import '../App.css';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';

function Home()  {
    return (
        <>
            <HeroSection />
            
        </>
    );
}

export default Home


