import React from 'react';
import Cards from '../components/Cards';
import App from '../App';


const Team = () => {
    return (
        <section id="team">
            <div className="full-page">
            <h1>Meet the professionals behind Soar Capital Growth</h1>
                <Cards />
               
            </div>
        </section>
    );
}

export default Team;
