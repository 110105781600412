import React from 'react';
import { TitleText} from '../components/TitleText';
import styled from 'styled-components';

const Header1 = styled.h1`
  font-size: 50px;
  text-align: center;
  font-family: 'Playfair Display';
  font-weight: 700;
  color: #0D3B66;
`;

const Sections = styled.div`
  background: #ffffff;
  color: #0D3B66;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const sections = [
    {
      title: 'Market Analysis & Research',
      text: 'Perform In-depth market studies to identify growth opportunities and understand consumer behavior.',

    },
    {
      title: 'Strategic Planning',
      text: 'Develop tailored business strategies that align with market trends and investor goals.'},
    {
      title: 'Brand Development',
      text: 'Craft compelling brand stories and identities that resonate with target audiences.'},
    {
      title: 'Operational Support',
      text: 'Streamline operations to enhance efficiency and scalability.'},
      {
        title: 'Growth Marketing',
        text: 'Implement data-driven marketing campaigns that drive customer acquisition and retention.'},
  ];
    
  function ServicesSection() {
    return (
        <div className="full-section">
            <div className="white-box">
                <Header1>Our Services</Header1>
                <TitleText sections={sections} />
            </div>
        </div>
    );
}
  
  export default ServicesSection;