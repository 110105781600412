import React from 'react';
import { AlternatingSections } from '../components/AlternatingSections';
import styled from 'styled-components';
import './AboutSection.css';
import Focus from '../assets/images/Overvie_and_Focus.jpg'
import Expertise from '../assets/images/Expertise.jpg'
import Committment from '../assets/images/Committment.jpg'
import Growth from '../assets/images/Growth.jpg'

const Header1 = styled.h1`
  font-size: 50px;
  text-align: center;
  font-family: 'Playfair Display';
  font-weight: 700;
  color: #0D3B66;
`;

const Paragraph = styled.p`
  font-size: 24px;
  text-align: left;
  font-family: 'Lato';
  font-weight: 400;
  color: #0D3B66;
  padding-bottom:40px;
`;

const sections = [
    {
      title: 'Overview and Focus',
      text: 'We are dedicated to transforming visionary ideas into thriving consumer brands and businesses. We specialize in partnering with investors to build and scale companies through expertise and strategic guidance.',
      image: Focus,
    },
    {
      title: 'Expertise and approach',
      text: "Our team is composed of seasoned professionals with extensive experience in growth marketing, brand development, and operational excellence. We focus on combining our industry knowledge with investment partners' resources to create robust growth strategies and maximize value.",
      image: Expertise,
    },
    {
      title: 'Support and Commitment',
      text: 'We provide comprehensive market analysis, strategic planning, brand positioning, and operational support. We are committed to building strong, competitive consumer brands and investing in their future.',
      image: Committment,
    },
    {
      title: 'Methodology and Philosophy',
      text: 'We use cutting-edge technology and innovative methods to identify opportunities, mitigate risks, and ensure sustainable success. Our focus is not solely on financial investment but on the future growth and development of portfolio companies.',
      image: Growth,
    },
  ];
    
function AboutSection()  {
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      
      <div className={`full-page-about ${isProduction ? 'prod' : ''}`}>
        <div className="title-about">
          <Header1>About Us</Header1>
          <Paragraph>Soar Capital Growth is a venture studio focused on building, scaling, and acquiring dynamic consumer brands. 
          </Paragraph>

        </div>
         

       <AlternatingSections sections={sections} />
     
      </div>
    );}
  
  export default AboutSection;