import React from 'react';
import styled from 'styled-components';
import * as Icon from 'react-feather';

const DetailsBarWrapper = styled.div`
  background-color: #0D3B66;
  border-radius: 7px;
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-bottom: 100px;
  @media (max-width: 768px) {
    padding-bottom: 80px;
    grid-row: 2;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px
`;

const TextOne = styled.p`
  text-align: center;
  color: #eaeaea;
  font-size: 25px;
  font-family: 'Lato';
  font-weight: bold;
`;

const TextTwo = styled.p`
  text-align: center;
  color: #eaeaea;
  font-size: 16px;
  font-family: 'Lato';
  font-weight: light;
  line-height: 18px;
`;

const BigCircle = styled.div`
  height: 50px;
  margin-top: 30px;
  width: 50px;
  background-color: #3FBA9B;
  border-radius: 100%;
  z-index: 22;
  margin-left: 10px;
`;

const SmallCircle = styled.div`
  position: absolute;
  margin-left: 10px;
  background-color: #9AD1D4;
  border-radius: 100%;
  height: 30px;
  width: 30px;
`;

const ContactsWrapper = styled.a`
  display: flex;
  width: 200px;
  height: 10px;
  margin-top: 50px;
  cursor: pointer;
  text-decoration: none;
`;

const ContactText = styled.div`
  color: #EAEAEA;

  font-size: 15px;
  margin-left: 10px;
`;

const SocialsWrapper = styled.div`
  display: flex;
  justify-content: center;
  display: flex;
  height: 10px;
  justify-content: center;
  bottom: 30px;
  position: absolute;
  cursor: pointer;
`;

const SocialIconWrapper = styled.a`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #9AD1D4;
  }
`;

const DetailsBar = () => {
  return (
    <DetailsBarWrapper>
      <TextWrapper>
        <TextOne>Contact Information</TextOne>
        <TextTwo>Provide our team with your contact information</TextTwo>
      </TextWrapper>
      <div>
        <ContactsWrapper href="tel:+1 917-618-5218">
          <Icon.Phone size={18} color="#9AD1D4" />
          <ContactText>+1 917-618-5218</ContactText>
        </ContactsWrapper>

        <ContactsWrapper href="mailto:contact@soar-cg.com">
          <Icon.Mail size={18} color="#9AD1D4" />
          <ContactText>contact@soar-cg.com</ContactText>
        </ContactsWrapper>
      </div>

      <div>
        <BigCircle></BigCircle>
        <SmallCircle></SmallCircle>
      </div>
    
    </DetailsBarWrapper>
  
    
 

  );
};

export default DetailsBar;