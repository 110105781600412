import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import avideo from '../assets/videos/video-2.mp4'

console.log('Local video path:', avideo);
console.log('Video URL:', process.env.NODE_ENV === 'development' ? avideo : process.env.REACT_APP_VIDEO_URL);

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src={avideo} type="video/mp4" autoPlay={true} loop muted />
      <h2>Investing in Growth</h2>
      <p>We partner with companies to drive success and build lasting value.</p>
      <div className='hero-btns'>
        <Button
          link={"/about"}
          href="/about"
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Learn More
        </Button>
 
      </div>
    </div>
  );
}

export default HeroSection;