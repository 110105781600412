import React from 'react';
import AboutSection from '../components/AboutSection';


const About = () => {
    return (
        <section id="about">
            <div className="container">
                
                <AboutSection/>
               
                
            </div>
        </section>
    );
}

export default About;
