import React from 'react';
import './TitleText.css';

export function TitleText({ sections }) {
  return (
    <div className="title.text-sections">
      {sections.map((section) => (
          <div className="text">
            <h2>{section.title}</h2>
            <p>{section.text}</p>
          </div>
        
      ))}
    </div>
  );
}