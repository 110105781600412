import React from 'react';
import './Footer.css';
import styled from 'styled-components';

const Paragraph = styled.p`
  font-size: 18px;
  text-align: left;
  font-family: 'Lato';
  font-weight: 400;
  color: #eaeaea;
    
`;

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <Paragraph>&copy; 2024 Soar Capital Growth LLC. All rights reserved.</Paragraph>
            </div>
        </footer>
    );
}

export default Footer;
