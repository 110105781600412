import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const InputSideWrapper = styled.form`
  height: auto;
  padding-bottom: 100px;
  position: relative;
  padding: 30px 30px 100px 30px;
`;

const InputWrapper = styled.div`
  border: 2px solid transparent;
  width: 90%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: Left;
  justify-content: Left;
`;

const Input = styled.input`
  color: #333;
  width: 100%;
  font-size: 15px;
  padding-top: 10px;
  border-bottom: 1px solid #0D3B66;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  outline: 0px transparent !important;
  font-family: 'Lato', system-ui;
  font-weight: 700;
`;

const Paragraph = styled.p`
  font-size: 24px;
  text-align: left;
  font-family: 'Lato';
  font-weight: 400;
  color: #0D3B66;

  @media (max-width: 768px) {
    font-size: 20px; /* Smaller font size for mobile */
    text-align: justify; /* Optional: Justify text for better readability on mobile */
  }

  @media (max-width: 480px) {
    font-size: 18px;
    margin-left: -10px; /* Further reduce font size for very small screens */
  }
`;

const MessageInput = styled.textarea`
  width: 100%;
  color: #333;
  font-size: 15px;
  padding-top: 50px;
  border-bottom: 1px solid #0D3B66;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  outline: 0px transparent !important;
  font-family: 'Lato', system-ui;
  font-weight: 700;
`;

const SubMitButton = styled.input`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #0D3B66;
  color: #EAEAEA;
  border: none;
  border-radius: 5px;
  padding: 12px 25px 12px 24px;
  cursor: pointer;
  font-family: 'Lato', system-ui;
  font-weight: 900;
`;

const LoadingButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #0D3B66;
  color: #EAEAEA;
  border: none;
  border-radius: 5px;
  padding: 12px 25px 12px 24px;
  cursor: pointer;
  font-family: 'Lato', system-ui;
`;

const InputSide = () => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [buttonLoading, setButtonLoading] = React.useState(false);
  
    const nameHandler = (e) => {
      setName(e.target.value);
    };
  
    const emailHandler = (e) => {
      setEmail(e.target.value);
    };
  
    const phoneHandler = (e) => {
      setPhone(e.target.value);
    };
    const messageHandler = (e) => {
      setMessage(e.target.value);
    };
  
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
      e.preventDefault();
      setButtonLoading(true);
      const response = await fetch('https://formspree.io/f/xvgpnyyd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, phone, message }),
      });
  
      if (response.ok) {
        navigate('/success');
        setButtonLoading(false);
      } else {
        alert('Failed to submit form');
      }
    };
  
    return (
      <InputSideWrapper onSubmit={handleSubmit}>
        <InputWrapper>
          <Paragraph>Name</Paragraph>
          <Input type="text" required placeholder="John Doe" value={name} onChange={nameHandler} />
        </InputWrapper>
        <InputWrapper>
          <Paragraph>Email</Paragraph>
          <Input
            type="email"
            placeholder="John.Doe@email.com"
            value={email}
            onChange={emailHandler}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <Paragraph>Phone</Paragraph>
          <Input
            type="tel"
            required
            placeholder="+1 718-000-0000"
            value={phone}
            onChange={phoneHandler}
          />
        </InputWrapper>
        <InputWrapper>
          <Paragraph>Message</Paragraph>
          <MessageInput
            required
            placeholder="Write your message"
            value={message}
            onChange={messageHandler}
          />
        </InputWrapper>
        {buttonLoading ? (
          <LoadingButton>Loading...</LoadingButton>
        ) : (
          <SubMitButton type="submit" value="Send Message" />
        )}
      </InputSideWrapper>
    );
  };
  
  export default InputSide;