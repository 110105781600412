import React from 'react';
import ServicesSection from '../components/ServicesSection';
import Footer from '../components/Footer';

const Services = () => {
    return (
        <section id="services">
            <div className="container">
                
                <ServicesSection/>
             
            </div>
        </section>
    );
}

export default Services;
